declare global {
  interface Window {
    env: {
      PID_API_URL?: string;
      PLUGIN_SENTRY_DSN?: string;
      ENVIRONMENT?: string;
    };
  }
}
/* tslint:enable:no-any */
export const setEnvVariablesToGlobalState = (): void => {
  window.env =
    {
      PID_API_URL: window.env?.PID_API_URL || process.env.REACT_APP_PID_API_URL,
      PLUGIN_SENTRY_DSN:
        window.env?.PLUGIN_SENTRY_DSN ||
        process.env.REACT_APP_PLUGIN_SENTRY_DSN,
      ENVIRONMENT: window.env?.ENVIRONMENT || process.env.ENVIRONMENT,
    } || {};
};
