/* eslint-disable no-console */
/* eslint-disable camelcase */
import * as Sentry from "@sentry/react";
// import { message as antdMessage } from "antd";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useClosePlugin } from "shared-plugin/src/utils/custom-hooks";
import { FieldsConfirmed } from "../screens/Perfectid";

type usePluginFetchReturns = {
  fieldsConfirmed: FieldsConfirmed;
  isResolved: boolean;
  isRejected: boolean;
};

export const useFillPartnerForm = (): usePluginFetchReturns => {
  // const TIME_TO_CLOSE_PLUGIN_IN_SECONDS = 5;
  const closePlugin = useClosePlugin();
  const { i18n, t } = useTranslation();
  const [status, setStatus] = React.useState<
    "started" | "resolved" | "rejected"
  >("started");
  const [fieldsConfirmed, setFieldsConfirmed] = React.useState<FieldsConfirmed>(
    {}
  );
  const { search } = useLocation();

  React.useEffect(() => {
    const sessionIdParam = new URLSearchParams(search).get("sessionId");
    const sse = new EventSource(
      `${window.env.PID_API_URL}/autocompletion?session_id=${sessionIdParam}`
    );

    sse.addEventListener("message", (message) => {
      fetchFieldsConfirmed(message, sessionIdParam);
      sse.close();
    });

    sse.addEventListener("error", (err) => {
      console.error("Error:", err);
      Sentry.captureException(err);
      setStatus("rejected");
      sse.close();
    });

    // The event stream format is a simple stream of text data
    // The event data is automatically wrapped in a `data` key
    // An example would be 'data: {"first_name": "Test", "last_name": "Example"}\n\n'
    // We need to destructure the data key and parse the data to create a json
    // See here: https://developer.mozilla.org/en-US/docs/Web/API/Server-sent_events/Using_server-sent_events#event_stream_format
    function fetchFieldsConfirmed(
      { data }: MessageEvent<string>,
      sessionId: string | null
    ) {
      const { fields, session_id: sessionIdFromBE } = JSON.parse(data);

      if (sessionIdFromBE !== sessionId) {
        const err = new Error(
          `Error: Url Param session id ${sessionId} does mot match backend sessin id ${sessionIdFromBE}`
        );
        console.error(err);
        Sentry.captureException(() => {
          throw err;
        });
        setStatus("rejected");
      }

      setFieldsConfirmed(fields);

      // Parse-Stringify-Hack to prevent app from braking when an error occurs
      // https://stackoverflow.com/questions/42376464/uncaught-domexception-failed-to-execute-postmessage-on-window-an-object-co
      window.parent.postMessage(
        JSON.parse(
          JSON.stringify({
            action: "dataConfirmed",
            value: fields,
          })
        ),
        "*"
      );
      console.log(t("alerts.windowClosing"));
      // antdMessage.loading({
      //   content: t("alerts.windowClosing"),
      //   duration: 10,
      //   style: {
      //     marginTop: "50px",
      //   },
      // });
      // setTimeout(() => {
      //   closePlugin();
      // }, TIME_TO_CLOSE_PLUGIN_IN_SECONDS * 1000);

      return setStatus("resolved");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closePlugin, i18n]);

  return {
    isResolved: status === "resolved",
    isRejected: status === "rejected",
    fieldsConfirmed,
  };
};
