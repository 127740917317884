import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import { useClosePlugin } from "shared-plugin/src/utils/custom-hooks";
import styled from "@emotion/styled";
import { Button, Layout } from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import PerfectIdLogo from "../../assets/perfectid_dark.svg";
import { isInsideIframe } from "../../utils/Helper";

type HeaderProps = {
  showBackButton: boolean;
};

const CustomHeader = styled(Layout.Header)({
  alignItems: "center",
  backgroundColor: "white",
  boxShadow: "0px 3px 5px rgba(100, 100, 100, 0.49)",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "0",
  zIndex: 10,
});

const Close = styled(
  ({
    children,
    onClick,
    ...props
  }: {
    children: React.ReactNode;
    onClick: () => void;
  }) => (
    <Button onClick={onClick} {...props}>
      {children}
    </Button>
  )
)({
  alignItems: "center",
  backgroundColor: "#001f3b",
  color: "white",
  display: "flex",
  fontSize: "1.7em",
  height: "100%",
  justifyContent: "center",
  width: 70,
  border: "none",
  borderRadius: "2px",
  "&:hover": {
    backgroundColor: "#001f3b",
    color: "white",
  },
});

const InvisibleDiv = styled.div({
  width: 70,
});

const Back = styled(
  ({
    children,
    onClick,
    ...props
  }: {
    children: React.ReactNode;
    onClick: () => void;
  }) => (
    <Button onClick={onClick} {...props}>
      {children}
    </Button>
  )
)({
  alignItems: "center",
  display: "flex",
  fontSize: "1.7em",
  height: "100%",
  justifyContent: "center",
  width: 70,
  border: "none",
  "&:hover": "none",
});

const Image = styled.img({
  objectFit: "contain",
  maxHeight: "35px",
  transform: "scale(0.8)",
});

// TODO: Move Header component inside of LayoutWrapper
export const Header = ({ showBackButton }: HeaderProps): JSX.Element => {
  const closePlugin = useClosePlugin();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <CustomHeader>
      {showBackButton ? (
        <Back onClick={() => handleGoBack()} data-testid="back-btn">
          <LeftOutlined />
        </Back>
      ) : (
        <InvisibleDiv />
      )}
      <Image src={PerfectIdLogo} />

      {isInsideIframe() ? (
        <Close onClick={() => closePlugin()}>
          <CloseOutlined />
        </Close>
      ) : (
        <InvisibleDiv />
      )}
    </CustomHeader>
  );
};
