import { useLocation } from "react-router-dom";
import { ErrorScreen } from "shared-plugin/src/screens/ErrorScreen";
import { useFillPartnerForm } from "../../hooks/fill-partner-form";
import { DataFilledScreen } from "../DataFilledScreen";
import { QrCodeScreen } from "../QrCodeScreen";

type FieldsRequested = string[];
export type FieldsConfirmed = { [key in FieldsRequested[number]]: string };

export function PerfectIdStartScreen(): JSX.Element {
  const { fieldsConfirmed, isResolved, isRejected } = useFillPartnerForm();
  const { search } = useLocation();

  if (isResolved) return <DataFilledScreen fieldsConfirmed={fieldsConfirmed} />;

  if (isRejected) return <ErrorScreen />;

  return <QrCodeScreen data={`http://getpia.app/autocomplete/${search}`} />;
}
