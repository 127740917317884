import styled from "@emotion/styled";

export const ParagraphText = styled.p({
  textAlign: "center",
  margin: "0 auto 10px",
  fontSize: 12,
  lineHeight: "19px",
  maxWidth: 400,
  width: "95%",
});
