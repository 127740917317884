import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createRoot } from "react-dom/client";
import { setEnvVariablesToGlobalState } from "./providers/Environment";
import { App } from "./App";
import "./i18nSets/i18n";
import "./index.less";

setEnvVariablesToGlobalState();

if (window.env.PLUGIN_SENTRY_DSN)
  Sentry.init({
    dsn: window.env.PLUGIN_SENTRY_DSN,
    environment: window.env.ENVIRONMENT,
    integrations: [new BrowserTracing()],
    sampleRate: 0.33,
    tracesSampleRate: 0.1,
  });

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
