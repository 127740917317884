import styled from "@emotion/styled";
import { Layout as AntdLayout } from "antd";

const StyledLink = styled.a({
  "&:link": {
    textDecoration: "inherit",
    color: "inherit",
  },
  "&:visited": {
    textDecoration: "inherit",
    color: "inherit",
  },
});

const CustomFooter = styled(AntdLayout.Footer)({
  padding: "20px 50px",
  backgroundColor: "#001f3b",
  color: "white",
  textAlign: "center",
});

// TODO: Move Footer component inside of LayoutWrapper
export const Footer: React.FC = (): JSX.Element => {
  return (
    <CustomFooter>
      <StyledLink
        target="_blank"
        href="https://www.getpia.app/"
        rel="noopener noreferrer"
      >
        Powered by Perfect-iD
      </StyledLink>
    </CustomFooter>
  );
};
