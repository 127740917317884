export const isInsideIframe = (): boolean =>
  window.location !== window.parent.location;

// https://gist.github.com/jed/982883#file-annotated-js
/* eslint-disable no-bitwise */
export const generateUuidv4 = (): string => {
  return `10000000-1000-4000-8000-100000000000`.replace(/[018]/g, (c: string) =>
    (
      Number(c) ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (Number(c) / 4)))
    ).toString(16)
  );
};

export const snakeOrCamelCaseToReadable = (name: string): string => {
  // split word in camel case or snakeCase
  const words = name.match(/[A-Za-z][a-z]*/g) || [];

  // transform each work to upper case
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
