import styled from "@emotion/styled";
import { Button as AntdButton } from "antd";

export const Button = styled(AntdButton)({
  width: 250,
  height: 50,
  marginBottom: 12,
  borderRadius: 20,
  marginLeft: 12,
  marginRight: 12,
  "&:hover": {
    backgroundColor: "#2BC6FF",
    color: "#FFFF",
  },
});

export const DarkButton = styled(Button)({
  backgroundColor: "#001F3B",
  borderColor: "#001F3B",
  color: "#FFF",
});

export const GradientButton = styled(AntdButton)({
  width: 250,
  height: 50,
  marginBottom: 12,
  borderRadius: 20,
  marginLeft: 12,
  marginRight: 12,
  "&, &:hover, &:focus, &:active": {
    backgroundColor: "#0CAFFF",
    background: "linear-gradient(#0CAFFF, #057BFF)",
    color: "#FFFF",
    borderColor: "#057BFF",
  },
});
