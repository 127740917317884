import * as Sentry from "@sentry/react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { LayoutWrapper } from "shared-plugin/src/components/Layout/LayoutWrapper";
import { ErrorScreen } from "shared-plugin/src/screens/ErrorScreen";
import { PerfectIdStartScreen } from "./screens/Perfectid";

// Always check serve.json redirects to static html pages when creating routes
export function App(): JSX.Element {
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Router>
        <Routes>
          <Route path="/" index />
          <Route element={<LayoutWrapper />}>
            <Route path="/perfectid" element={<PerfectIdStartScreen />} />
            <Route path="/server-error" element={<ErrorScreen />} />
          </Route>
        </Routes>
      </Router>
    </Sentry.ErrorBoundary>
  );
}
