import styled from "@emotion/styled";
import { Layout as AntdLayout } from "antd";
import { Outlet } from "react-router-dom";
import { Header } from "shared-plugin/src/components/Layout/Header";
import { Footer } from "./Footer";

type Props = {
  readonly showHeaderBackButton?: boolean;
};

const Layout = styled(AntdLayout)({
  overflow: "hidden",
  width: "100vw",
  height: "100vh",
  background: "#FFFFFF",
  "@media (min-width: 768px)": {
    width: 500,
    margin: "0 auto",
  },
});

export const Content = styled(AntdLayout.Content)({
  overflowY: "auto",
  overflowX: "hidden",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
});

export const MainWrapper = styled.div({
  margin: "12px",
  maxWidth: "450px",
  width: "90%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
});

export const LayoutWrapper = ({
  showHeaderBackButton = false,
}: Props): JSX.Element => (
  <Layout>
    <Header showBackButton={showHeaderBackButton} />
    <Content>
      <MainWrapper>
        <Outlet />
      </MainWrapper>
    </Content>
    <Footer />
  </Layout>
);
