import styled from "@emotion/styled";
import { GradientButton } from "shared-plugin/src/components/Buttons/Button";
import { ParagraphHeader } from "shared-plugin/src/components/Paragraphs/ParagraphHeader";
import { ParagraphText } from "shared-plugin/src/components/Paragraphs/ParagraphText";
import { useClosePlugin } from "shared-plugin/src/utils/custom-hooks";
import { snakeOrCamelCaseToReadable } from "shared-plugin/src/utils/Helper";
import { useTranslation } from "react-i18next";
import { FieldsConfirmed } from "./Perfectid";

const Header = styled(ParagraphHeader)`
  font-size: 42px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #001f3b;
  font-weight: 500;
`;

const DarkButton = styled(GradientButton)`
  font-size: 14px;
  margin-top: 48px;
  width: 150px;
  color: #ffff;
  background-color: #2bc6ff;
`;

export const CustomParagraphText = styled(ParagraphText)`
  background-color: #001f3b;
  border-color: #001f3b;
  color: #fff;
  text-align: left;
  width: 300px;
  margin-bottom: 12px;
  border-radius: 20px;
  margin-left: 12px;
  margin-right: 12px;
  padding: 5px 24px;
  white-space: pre-wrap;
`;

type DataFilledScreenProps = {
  fieldsConfirmed: FieldsConfirmed;
};

export function DataFilledScreen({
  fieldsConfirmed,
}: DataFilledScreenProps): JSX.Element {
  const closePlugin = useClosePlugin();
  const { t } = useTranslation();

  return (
    <>
      <Header>{t("infos.data")}</Header>
      {Object.entries(fieldsConfirmed).map(([field, value]) => (
        <CustomParagraphText key={field + value} data-testid="disclosedField">
          <b>{snakeOrCamelCaseToReadable(field)}</b>:
          <br />
          {String(value)}
        </CustomParagraphText>
      ))}
      <DarkButton onClick={() => closePlugin()}>
        {t("buttons.continue")}
      </DarkButton>
    </>
  );
}
