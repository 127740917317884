import styled from "@emotion/styled";

export const ParagraphHeader = styled.h1({
  textAlign: "center",
  margin: "0 auto 15px",
  fontSize: 30,
  fontWeight: 300,
  lineHeight: "37px",
  maxWidth: 400,
  width: "95%",
});
