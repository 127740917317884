import styled from "@emotion/styled";

export const ParagraphTitle = styled.h2({
  textAlign: "center",
  margin: "0 auto 10px",
  fontSize: 18,
  fontWeight: 300,
  maxWidth: 400,
  width: "95%",
});
