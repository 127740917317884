import { ClosePluginButton } from "shared-plugin/src/components/Buttons/ClosePluginButton";
import { ParagraphHeader } from "shared-plugin/src/components/Paragraphs/ParagraphHeader";
import { ScreenIcon } from "shared-plugin/src/components/ScreenIcon";
import { isInsideIframe } from "shared-plugin/src/utils/Helper";
import Robot from "shared-plugin/src/assets/robot.png";
import { useTranslation } from "react-i18next";
import { ParagraphText } from "../components/Paragraphs/ParagraphText";

type Props = { title?: string; subtitle?: string; buttonText?: string };
export const ErrorScreen = ({
  title,
  subtitle,
  buttonText,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <ScreenIcon src={Robot} alt="check" />
      <ParagraphHeader>{title || t("titles.errorScreen")}</ParagraphHeader>
      {subtitle && <ParagraphText>{subtitle}</ParagraphText>}
      {isInsideIframe() && (
        <ClosePluginButton>
          {buttonText || t("buttons.closePlugin")}
        </ClosePluginButton>
      )}
    </>
  );
};
