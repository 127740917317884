import i18n from "i18next";
import merge from "lodash.merge";
import { initReactI18next } from "react-i18next";
import translationSharedEN from "shared-plugin/src/i18nSets/locales/en/translation.json";
import translationSharedDE from "shared-plugin/src/i18nSets/locales/de/translation.json";
import translationSharedES from "shared-plugin/src/i18nSets/locales/es/translation.json";
import translationSharedPT from "shared-plugin/src/i18nSets/locales/pt/translation.json";
import translationSharedFR from "shared-plugin/src/i18nSets/locales/fr/translation.json";
import translationDE from "./locales/de/translation.json";
import translationEN from "./locales/en/translation.json";
import translationES from "./locales/es/translation.json";
import translationPT from "./locales/pt/translation.json";
import translationFR from "./locales/fr/translation.json";

const resources = {
  en: {
    translation: merge(translationEN, translationSharedEN),
  },
  de: {
    translation: merge(translationDE, translationSharedDE),
  },
  es: {
    translation: merge(translationES, translationSharedES),
  },
  pt: {
    translation: merge(translationPT, translationSharedPT),
  },
  fr: {
    translation: merge(translationFR, translationSharedFR),
  },
};
declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  debug: false,

  interpolation: {
    escapeValue: false,
  },
  returnNull: false,
});

/* eslint-disable import/no-default-export */
export default i18n;
