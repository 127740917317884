import * as React from "react";
import { useClosePlugin } from "shared-plugin/src/utils/custom-hooks";
import { DarkButton } from "shared-plugin/src/components/Buttons/Button";

export const ClosePluginButton = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const closePlugin = useClosePlugin();

  return (
    <DarkButton
      data-test-id="close-plugin-button"
      onClick={() => closePlugin()}
    >
      {children}
    </DarkButton>
  );
};
