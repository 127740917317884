import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../providers/Context";

// TODO: Create a delay as set timeout and remember to clear it in the useEffect
export const useClosePlugin = (): (() => void) => {
  const navigate = useNavigate();
  const { startPageIndex, setStartPageIndex } = React.useContext(Context);

  const closePlugin = React.useCallback(() => {
    // eslint-disable-next-line no-restricted-globals
    const navigateSteps = startPageIndex - history.length;
    // Reset browser history to initial state when plugin was first opened
    if (navigateSteps) navigate(navigateSteps);
    setStartPageIndex(navigate.length + navigateSteps);

    return window.parent.postMessage({ action: "close" }, "*");
  }, [navigate, setStartPageIndex, startPageIndex]);

  return closePlugin;
};
