/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from "react";

type Props = {
  readonly children?: React.ReactNode;
};

type ContextProps = {
  startPageIndex: number;
  setStartPageIndex: (index: number) => void;
};

const initialContext: ContextProps = {
  startPageIndex: 0,
  setStartPageIndex: () => {},
};

export const Context = React.createContext<ContextProps>(initialContext);

export const Provider = ({ children }: Props): JSX.Element => {
  const [startPageIndex, setStartPageIndex] = React.useState(
    initialContext.startPageIndex
  );

  const value = React.useMemo(
    () => ({ startPageIndex, setStartPageIndex }),
    [startPageIndex, setStartPageIndex]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
