/* eslint-disable camelcase */
import styled from "@emotion/styled";
import { ParagraphHeader } from "shared-plugin/src/components/Paragraphs/ParagraphHeader";
import { ParagraphText } from "shared-plugin/src/components/Paragraphs/ParagraphText";
import { ParagraphTitle } from "shared-plugin/src/components/Paragraphs/ParagraphTitle";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";

const Header = styled(ParagraphHeader)`
  font-size: 34px;
  margin-bottom: 30px;
  color: #001f3b;
  font-weight: 600;
`;

const Title = styled(ParagraphTitle)`
  font-size: 48px;
  color: #26afe2;
  font-weight: 500;
`;

export function QrCodeScreen({ data }: { data: string }): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Header>{t("titles.connect")}</Header>
      <QRCode
        value={data}
        size={300}
        data-testid="qr-code-img"
        renderAs="svg"
        fgColor="#001F3B"
        includeMargin
        style={{
          borderRadius: "10px",
          borderStyle: "solid",
          borderColor: "001F3B",
          marginTop: "40px",
          padding: "15px",
        }}
      />
      <Title>{t("infos.scanQr")}</Title>
      <ParagraphText>{t("infos.scanInstructions")}</ParagraphText>
    </>
  );
}
